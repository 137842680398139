import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#64748B",
    },
    thirdYellow: {
      main: "yellow",
    },
    chartBtn: {
      main: "#7ac23138",
    },
    background: {
      default: "#222222",
    },
  },
});

export default theme;
