import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import { ThemeProvider } from "@mui/material";
import theme from "./utils/theme";
import { RecoilRoot } from "recoil";

const Homepage = lazy(() => import('./Pages/Homepage'));
const ProfilePage = lazy(() => import('./Pages/Profilepage'));
const CreateNodePage = lazy(() => import('./Pages/CreateNodePage'));
const LandingPage = lazy(() => import('./Pages/LandingPage'));
const NotesPage = lazy(() => import('./Pages/NotesPage.js'));

function App() {
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/webapp" element={<Homepage />} />
              <Route path="/notes" element={<NotesPage />} />
              <Route path='/profile' element={<ProfilePage />} />
              <Route path="/create-note" element={<CreateNodePage />} />
            </Routes>
          </Suspense>
        </Router>
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
